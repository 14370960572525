<template>
  <div class="sub-categorie-champ-setting" v-if="getCategoriesCategorie">
    <div class="header">
      <div class="titleG">
        <div @click="handelCancel($event)" class="fleche">
          <font-awesome-icon class="" icon="arrow-circle-left" />
        </div>
        <div class="titre">
          <h3 class="font">
            Gestion de sous-catégorie : {{ getCategoriesCategorie.name }}
          </h3>
        </div>
      </div>

      <div class="button">
        <b-button
          class="addSousCategorie"
          size="sm"
          variant="success"
          v-b-modal.addChampToSubCateg
        >
          <font-awesome-icon class="" icon="plus" />
          Ajouter des champs
        </b-button>
      </div>
    </div>
    <div class="color-titre"><h6>Liste des champs</h6></div>

    <hr />
    <div class="message">
      <div v-if="getCategorieLoading" class="three-dots-loading">
        Chargement en cours
      </div>
      <div v-if="getCategorieError" class="error">
        <ul v-if="Array.isArray(getCategorieError)">
          <li v-for="(e, index) in getCategorieError" :key="index">
            {{ e }}
          </li>
        </ul>
        <span v-else>{{ getCategorieError }}</span>
      </div>
    </div>
    <div class="card-scene" ref="cardSceneCategory">
      <Container
        :drop-placeholder="dropPlaceholderOptions"
        :lock-axis="'y'"
        style="cursor: move;"
        @drop="onColumnDrop"
        drag-handle-selector=".column-drag-container "
      >
        <Draggable
          v-for="champ in getCategoriesCategorie.fields"
          :key="champ.id"
        >
          <div class="column-drag-container ">
            <div class="card-column-header">
              <div class="column-drag-handle">
                ☰
              </div>
              <div class="input-title-champ">
                <div class="body-drag">
                  <input
                    class="input-focus"
                    placeholder="Nom de champ"
                    @change="updateNameChamp($event, champ)"
                    title="Modifier le nom de champ"
                    :value="champ.name"
                  />
                </div>

                <b-form-checkbox
                  v-model="champ.subcategory_header"
                  switch
                  size="sm"
                  :value="1"
                  :unchecked-value="0"
                  @change="updateEnteteChamp($event, champ)"
                  >Champ entête
                </b-form-checkbox>

                <div class="action-drag">
                  <div class="icon-sous-categorie">
                    <b-button
                      size="sm"
                      class="btn-left non-drag-area"
                      @click="tryDeleteChamp(champ)"
                      title="Supprimer un champ "
                    >
                      <font-awesome-icon class icon="times" />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      </Container>
    </div>
    <!-- Add Champ to Sous_Categorie Modal -->
    <b-modal
      no-close-on-backdrop
      id="addChampToSubCateg"
      ref="addChampToSubCateg"
      title="Ajouter des champs"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form @submit.prevent="addChamps">
        <b-form-group label="">
          <b-form-checkbox-group
            v-model="ListChampToAdd.type"
            :options="ComputedgetCustomFieldsMenu"
            name="flavour-1"
            @change="changeTypeInModal"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group label="Liste des champs" label-for="categorie-select">
          <multiselect
            class="multiselectS"
            v-model="ListChampToAdd.listChamp"
            :options="getCustomFieldsCategorie"
            placeholder="Rechercher"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            :custom-label="nameWithLang"
            label="name"
            track-by="name"
            :multiple="true"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </multiselect>
        </b-form-group>
        <div class="message">
          <div v-if="getCategorieLoading" class="loading">
            Chargement en cours...
          </div>
          <div v-if="getCategorieError" class="error">
            <ul v-if="Array.isArray(getCategorieError)">
              <li v-for="(e, index) in getCategorieError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getCategorieError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('addChampToSubCateg')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button
            variant="success"
            type="submit"
            :disabled="getCategorieLoading"
          >
            Valider
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- Dellete Champ -->
    <b-modal
      no-close-on-backdrop
      ref="deleteChampModal"
      id="deleteChampModal"
      title="Supprimer un champ"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <p class="mb-3">
        Êtes-vous certain de vouloir supprimer la champ
        <strong>
          {{ champToDelete ? champToDelete.name : '' }}
        </strong>
        ?
      </p>
      <div class="message">
        <div v-if="getCategorieLoading" class="loading">
          Chargement en cours...
        </div>
        <div v-if="getCategorieError" class="error">
          <ul v-if="Array.isArray(getCategorieError)">
            <li v-for="(e, index) in getCategorieError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getCategorieError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('deleteChampModal')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button variant="success" @click="deleteChamp">
          Valider
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'

import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      selectedCategorie: null,
      ListChampToAdd: {
        type: [],
        listChamp: []
      },
      customizable: 1,
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      champToDelete: null
    }
  },

  methods: {
    ...mapActions([
      'resetErrorCategorie',
      'fetchAllTablesCustomFields',
      'fetchListChampforSubCategorie',
      'resetCategorieListCustemFields',
      'updateCustemFieldSubCategorie',
      'fechlistFieldSubCategorie',
      'addCutemFieldsToSubCategorie',
      'fetchOneSubCategorie',
      'updateOrderCustemFieldsSubCategorie',
      'deleteCustemFieldSubCategorie'
    ]),
    //retour
    handelCancel() {
      if (this.$route && this.$route.name == 'subCategorieContactUpdate') {
        this.$router
          .replace(
            '/setting/categorie-contact/' +
              this.$route.params.id +
              '/sous-categorie'
          )
          .catch(() => {})
      }
      if (this.$route && this.$route.name == 'subCategorieProjetUpdate') {
        this.$router
          .replace(
            '/setting/categorie-projet/' +
              this.$route.params.id +
              '/sous-categorie'
          )
          .catch(() => {})
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    resetModal() {
      this.champToDelete = null
      this.ListChampToAdd = {
        type: [],
        listChamp: []
      }
      this.resetErrorCategorie()
      this.resetCategorieListCustemFields()
    },
    //change type in modal
    async changeTypeInModal() {
      await this.fetchListChampforSubCategorie({
        type: this.ListChampToAdd.type,
        idSubCat: this.$route.params.idSubCategorie
      })
    },
    //add champ
    async addChamps() {
      const responce = await this.addCutemFieldsToSubCategorie({
        idSubCat: this.$route.params.idSubCategorie,
        ChampsToAdd: this.ListChampToAdd.listChamp
      })
      if (responce) {
        // scroll when add new champ
        this.$nextTick(() => {
          this.$refs.cardSceneCategory.scrollTop = this.$refs.cardSceneCategory.scrollHeight
          this.hideModal('addChampToSubCateg')
        })
      }
    },
    //updated name Champ
    updateNameChamp(e, champ) {
      this.updateCustemFieldSubCategorie({
        idCategorie: this.getCategoriesCategorie.id,
        champ: champ,
        data: { name: e.target.value }
      })
    },
    //update champ entet
    updateEnteteChamp(e, champ) {
      this.updateCustemFieldSubCategorie({
        idCategorie: this.getCategoriesCategorie.id,
        champ: champ,
        data: { is_subcategory_header: e }
      })
    },

    //show modal delete Champ
    tryDeleteChamp(champ) {
      this.champToDelete = champ
      this.$refs['deleteChampModal'].show()
    },
    //delete subCategorieChamp
    async deleteChamp() {
      const response = await this.deleteCustemFieldSubCategorie({
        idCategorie: this.getCategoriesCategorie.id,
        champ: this.champToDelete
      })
      if (response) {
        this.hideModal('deleteChampModal')
      }
    },
    onColumnDrop(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      let custemFieldsOrder = this.applyDrag(
        this.getCategoriesCategorie.fields,
        dropResult
      )
      this.updateOrderCustemFieldsSubCategorie(custemFieldsOrder)
      this.updateCustemFieldSubCategorie({
        idCategorie: this.getCategoriesCategorie.id,
        champ: this.getCategoriesCategorie.fields[addedIndex],
        data: {
          order: addedIndex
        }
      })
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    nameWithLang({ name, key }) {
      return `${name} (${key})`
    }
  },
  components: {
    Container,
    Draggable
  },
  computed: {
    ...mapGetters([
      'getCategorieLoading',
      'getCategorieError',
      'getCustomFieldsMenu',
      'getCustomFieldsCategorie',
      'getCategoriesCategorie'
    ]),

    ComputedgetCustomFieldsMenu() {
      let table = []
      table = this.getCustomFieldsMenu.map(item => {
        return { text: item.name, value: item.id }
      })
      return table
    }
  },
  async mounted() {
    await this.fetchOneSubCategorie({
      idCategorie: this.$route.params.id,
      idSubCategorie: this.$route.params.idSubCategorie
    })
    if (!this.getCategoriesCategorie) {
      if (this.$route && this.$route.name == 'subCategorieContactUpdate') {
        this.$router
          .replace(
            '/setting/categorie-contact/' +
              this.$route.params.id +
              '/sous-categorie'
          )
          .catch(() => {})
      }
      if (this.$route && this.$route.name == 'subCategorieProjetUpdate') {
        this.$router
          .replace(
            '/setting/categorie-projet/' +
              this.$route.params.id +
              '/sous-categorie'
          )
          .catch(() => {})
      }
    }
    await this.fechlistFieldSubCategorie(this.$route.params.idSubCategorie)
    await this.fetchAllTablesCustomFields(this.customizable)
  }
}
</script>
<style lang="scss" scoped>
.sub-categorie-champ-setting {
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // white-space: nowrap;
    .titleG {
      display: flex;
      align-items: center;
    }
    .titre {
      margin-left: 10px;
    }
  }
  .color-titre {
    color: #007bff;
    margin-left: 28px;
    margin: 3px;
  }
  .button {
    .addSousCategorie {
      font-size: 15px;
    }
  }
  .message {
    height: 25px;
  }
  .select-color {
    margin-right: 5px;
  }
  .fleche {
    padding-bottom: 8px;
    font-size: 22px;
    cursor: pointer;
    &:hover {
      color: #6c757d;
    }
  }
  .card-scene {
    height: calc(100% - 180px);
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    box-shadow: none;
    background-color: transparent;
    color: #7b8785;
    background-color: transparent;
    border-color: transparent;
  }
  @media screen and (max-width: 580px) {
    .header {
      white-space: unset;
      .titre {
        .font {
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
    .input-focus {
      width: 100%;
    }
  }
  @media screen and (max-width: 280px) {
    .smooth-dnd-container {
      min-width: 100%;
    }
    .input-title-champ {
      display: block;
    }
  }
}
</style>
<style lang="scss" scoped>
.column-drag-handle,
.ss-column-drag-handle {
  position: absolute;
  top: 12px;
  left: 6px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .ss-column-drag-handle {
//   top: 5px;
//   left: 3px;
// }

.input-title-champ {
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
  .body-drag {
    display: flex;
    -webkit-box-align: center;
    .input-focus {
      &:focus {
        background-color: #f8f9fa;
      }
    }
    input {
      color: black;
      overflow: visible;
      background-color: transparent;
      border: 0px;
      font-size: 15px;
      text-overflow: ellipsis;
    }
    .custom-select-sm {
      width: 139px;
    }
  }
  .icon-sous-categorie {
    align-self: center;
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.icon-sous-categorie {
  .btn-left {
    color: #495057ab;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    margin-right: 3px;
  }
  .btn-secondary:focus,
  .btn-secondary.focus {
    color: #495057ab;
    background-color: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.075);
  }
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.card-column-header {
  position: relative;
  width: 100%;
  padding: 9px;
}
.smooth-dnd-container.horizontal {
  display: flex;
}
.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  position: relative;
  overflow: visible;
  margin-top: 5px;
  border: 1px solid #6c757d52;
  padding: 4px;
  background-color: #b0e6f252;
  width: 100%;
  border-radius: 4px;
}
p {
  margin-top: 0;
  margin-bottom: 0rem;
}
.smooth-dnd-container {
  margin: 3px;
}
.smooth-dnd-container {
  position: relative;
  min-height: 30px;
  min-width: 255px;
  padding-bottom: 16px;
}
.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
  border: 1px solid #6c757d4d;
  margin-right: 8px;
  background-color: #ebebeb5c;
  border-radius: 4px;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 7px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12px;
  width: 77px;
}
</style>
<style scoped>
.slide-toggle {
  display: none;
}
.slidemenu {
  border: 0px;
  background-color: transparent;
  overflow: hidden;
}

.slidemenu label {
  width: 25%;
  text-align: center;
  display: block;
  float: left;
  color: #333;
  opacity: 4.2;
}

.slidemenu label:hover {
  cursor: pointer;
  color: #666;
}

.slidemenu label span {
  display: block;
  padding: 10px;
}

.slidemenu label .icon {
  font-size: 20px;
  border: solid 2px #333;
  text-align: center;
  height: 50px;
  width: 50px;
  display: block;
  margin: 0 auto;
  line-height: 50px;
}
.multiselectS {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
  margin-bottom: 3em;
}
</style>
<style lang="scss">
.multiselectC {
  min-height: 29px;
  width: 200px;
  margin-left: 5px;
  .multiselect__select {
    height: 32px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
  }
  .multiselect__tags {
    min-height: 11px;

    padding: 3px 40px 0 8px;
  }
  .multiselect__placeholder {
    margin-bottom: 3px;
  }
}
.ClassType {
  display: grid;
  .span-title {
    padding-left: 10px;
    font-size: 13px;
  }
}
.ClassSousType {
  display: inline;
  .span-title {
    padding-left: 10px;
    font-size: 13px;
  }
}
</style>
